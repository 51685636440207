<template>
    <div></div>
  </template>
  
  <script>
  export default {
      data() {
          return {
  
          }
      },
      created() {
          this.downApp()
      },
      mounted() {
  
      },
      methods: {
          downApp(){
              var u = navigator.userAgent, 
              isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
              isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
              urls = {
                  'android':'https://play.google.com/store/apps/details?id=com.zwcloud.ruision.holowits&hl=en&gl=US',
                  'ios':'https://apps.apple.com/us/app/holoz/id6478387691',
                  'other':''
              };          
  
              //跳转
              if(isAndroid){
                  window.location.href=urls.android;
              }else if(isiOS){
                  window.location.href=urls.ios;
              }
            //   else{
            //       window.location.href=urls.other;
            //   }
          }
      }
  }
  </script>
  
  <style>
  
  </style>